<template>
    <div>
        <CModal
                title="Add Comment "
                :show.sync="addCommentmodal"
                @update:show="closeCommentModal"
                centered
                size="xl"
        >
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            <h4> [#{{ ticket_data.ticket_id }}] {{ticket_data.ticket_title}}
                                {{ticket_data.status}} </h4>
                        </CCardHeader>
                        <CCardBody>
                            <wysiwyg v-model="comment_html"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CModal>

        <CModal
                title="Edit Comment "
                :show.sync="editModal"
                @update:show="closeEditModal"
                centered
                size="xl"
        >
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            <h4> [#{{ ticket_data.ticket_id }}] {{ticket_data.ticket_title}}
                                {{ticket_data.status}} </h4>
                        </CCardHeader>
                        <CCardBody v-if="selected_comment">
                            <wysiwyg v-model="selected_comment.com_text"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CModal>

        <CModal
                title="Edit Reply "
                :show.sync="editReplyModal"
                @update:show="closeReplyEditModal"
                centered
                size="xl"
        >
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            <h4> [#{{ ticket_data.ticket_id }}] {{ticket_data.ticket_title}}
                                {{ticket_data.status}} </h4>
                        </CCardHeader>
                        <CCardBody v-if="selected_reply">
                            <wysiwyg v-model="selected_reply.com_m_text"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CModal>

        <CModal
                title="Are you sure that you want to delete comment?"
                :show.sync="deleteModal"
                @update:show="closeDelModal"
                centered
                size="s"
        >
        </CModal>

        <CModal
                title="Are you sure that you want to delete reply?"
                :show.sync="replyDeleteModal"
                @update:show="closeReplyDelModal"
                centered
                size="s"
        >
        </CModal>
        <CModal
                title="Reply to comment"
                :show.sync="replyModalFlag"
                @update:show="closeReplyCommentModal"
                centered
                size="xl"
        >
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            <CListGroupItem v-if="reply_to_comment"
                                            href="#"
                                            active
                                            class="flex-column align-items-start"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1"><i> {{reply_to_comment.usr_firstname}}
                                        {{reply_to_comment.usr_lastname}}</i>
                                        Commented:</h5>
                                    <small>{{new Date(reply_to_comment.com_timestamp).toISOString() }} </small>
                                </div>
                                <p v-html="reply_to_comment.com_text" class="mb-1">
                                </p>
                            </CListGroupItem>
                        </CCardHeader>
                        <CCardBody>
                            <wysiwyg v-model="com_reply"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CModal>

        <CModal
                title="Edit Description"
                :show.sync="editDescription"
                @update:show="closeDescModal"
                centered
                size="xl"
        >
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>

                            <h4> Ticket Title </h4>
                        </CCardHeader>
                        <CCardBody>

                            <CInput style="font-size: 20px"
                                    label="Ticket Title"
                                    size="lg"
                                    v-model="ticket_data.ticket_title">
                            </CInput>

                        </CCardBody>
                    </CCard>

                    <CCard>
                        <CCardHeader>

                            <h4> Ticket Description </h4>
                        </CCardHeader>
                        <CCardBody>

                            <CCardBody>

                                <wysiwyg v-model="ticket_data.ticket_description"/>
                            </CCardBody>
                        </CCardBody>

                    </CCard>
                </CCol>
            </CRow>
        </CModal>

        <CRow>
            <CCol sm="8">
                <CCard>
                    <CCardHeader>
                        <h2> Ticket Title
                            <CButton pill color="primary" @click="editDescription = true"
                                     v-if="ticket_data.usr_id == whoamI"> Edit
                            </CButton>
                        </h2>
                    </CCardHeader>
                    <CCardBody>

                        <h4> [#{{ ticket_data.ticket_id }}] {{ticket_data.ticket_title}} {{ticket_data.status}} </h4>
                    </CCardBody>
                </CCard>
                <CCard>
                    <CCardHeader>
                        <h2>Ticket Description </h2>
                    </CCardHeader>
                    <CCardBody>

                        <CJumbotron>
                            <p v-html="ticket_data.ticket_description"></p>
                        </CJumbotron>

                    </CCardBody>
                </CCard>

                <CCard>
                    <CCardHeader>
                        <h2>Ticket Comments </h2>
                        <CButton pill color="success" @click="addCommentmodal=true"> Add comment</CButton>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup>
                            <CListGroupItem @dblclick="toClipBoardCallback(comment)" v-for="comment in ticket_comments"
                                            href="#"
                                            class="flex-column align-items-start"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1"><i> {{comment.usr_firstname}} {{comment.usr_lastname}}</i>
                                        Commented:</h5>
                                    <CCol>


                                        <CBadge style="padding-block: 5px;" color="warning">Last edit: {{new
                                            Date(comment.com_lastedit).toISOString() }}
                                        </CBadge>
                                        <CBadge style="padding-block: 5px; margin-left: 5px" color="info">Commented:
                                            {{new
                                            Date(comment.com_timestamp).toISOString() }}
                                        </CBadge>
                                    </CCol>
                                </div>
                                <p v-html="comment.com_text" class="mb-1">
                                </p>
                                <p>
                                    <CButton pill color="primary" size='sm' v-if="comment.usr_id == whoamI"
                                             @click="editCallback(comment)"> Edit
                                    </CButton>
                                    <CButton style="margin-left: 5px" pill color="danger" size='sm'
                                             v-if="comment.usr_id == whoamI" @click="deleteCallback(comment)"> Delete
                                    </CButton>
                                    <CButton style="margin-left: 5px" pill color="info" size='sm'
                                             @click="replyModal(comment)"> Reply
                                    </CButton>

                                </p>
                                <CListGroupItem v-for="reply in replies[comment.com_id]"
                                                href="#"
                                                class="flex-column align-items-start"
                                >
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-1"><i> {{reply.usr_firstname}} {{reply.usr_lastname}}</i>
                                            Replied:</h5>
                                        <CCol>


                                            <CBadge style="padding-block: 5px;" color="warning">Last edit: {{new
                                                Date(reply.com_m_lastedit).toISOString() }}
                                            </CBadge>
                                            <CBadge style="padding-block: 5px; margin-left: 5px" color="info">Commented:
                                                {{new
                                                Date(reply.com_m_timestamp).toISOString() }}
                                            </CBadge>
                                        </CCol>
                                    </div>
                                    <p v-html="reply.com_m_text" class="mb-1">
                                    </p>
                                    <p>
                                        <CButton pill color="primary" size='sm' v-if="reply.usr_id == whoamI"
                                                 @click="editReplyCallback(reply)"> Edit
                                        </CButton>
                                        <CButton style="margin-left: 5px" pill color="danger" size='sm'
                                                 v-if="reply.usr_id == whoamI" @click="replyDelete(reply)"> Delete
                                        </CButton>
                                    </p>

                                </CListGroupItem>
                            </CListGroupItem>
                        </CListGroup>
                    </CCardBody>
                </CCard>

            </CCol>
            <CCol sm="4">
                <CCard>

                    <CCardHeader>
                        <h2> Ticket Details </h2>
                    </CCardHeader>
                    <CCardBody>
                        <CForm>
                            <CInput
                                    label="Created date"
                                    :value.sync="ticket_data.ticket_created"
                                    horizontal
                                    plaintext

                            />
                            <CInput
                                    description=""
                                    label="Assigned to:"
                                    horizontal
                                    :value.sync="ticket_data.usr_email"
                                    disabled

                            />
                            <CInput
                                    description=""
                                    label="Reported by:"
                                    horizontal
                                    :value.sync="ticket_data.ticket_reporter"
                                    autocomplete="name"
                                    plaintext
                            />
                            <CInput
                                    description=""
                                    label="Ticket status:"
                                    horizontal
                                    :value.sync="ticket_data.ticket_state_name"
                                    autocomplete="name"
                                    plaintext
                            />
                            <CInput
                                    description=""
                                    label="Ticket priority"
                                    horizontal
                                    :value.sync="ticket_data.ticket_prio_name"
                                    autocomplete="name"
                                    plaintext
                            />

                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>import axios from 'axios';
import {CChartPie} from "@coreui/vue-chartjs";
import ButtonGroups from "../buttons/ButtonGroups";

export default {
    name: 'TicketDetails',
    components: {ButtonGroups, CChartPie},
    computed: {
        defaultDatasets() {
            return [
                {
                    backgroundColor: [
                        '#41B883',
                        '#E46651',
                    ],
                    data: [1, 2]
                }
            ]
        }
    },
    data() {
        return {

            ticket_data: null,
            replies: null,
            ticket_comments: null,
            addCommentmodal: null,
            editDescription: null,
            editModal: false,
            editReplyModal: false,
            reply_to_comment: null,
            comment_html: null,
            deleteModal: false,
            replyModalFlag: false,
            replyDeleteModal: false,
            com_reply: "",
            selected_comment: null,
            selected_reply: null,
            comment: {com_id: null}
        }
    },
    methods: {

        toClipBoardCallback(comment) {
            navigator.clipboard.writeText(
                `${comment.usr_firstname} ${comment.usr_lastname} @ ${new Date(comment.com_timestamp).toISOString()}
                                Commented:
                                ${comment.com_text}}
                                `).then(
                this.$toast.success("Copied to clipboard")).catch(error => {
                this.$toast.error(error)
            })
        },
        replyModal(comment) {
            this.reply_to_comment = comment;
            this.replyModalFlag = true
        },
        getDetails(ticket) {
            console.log(ticket);
        },
        getTicket() {

            axios.get(this.$baseURL + '/ticket/get_by_id?ticket_id=' + this.$route.params.ticket_id, {})
                .then((res) => {
                    this.ticket_data = res.data.ticket_data;
                    this.ticket_comments = res.data.comment_data;
                    this.replies = res.data.replies;
                }).catch((error) => {
                console.log(error);
            })


        },
        editReplyCallback(comment) {
            this.editReplyModal = true;
            this.selected_reply = comment;
        },
        editCallback(comment) {
            this.editModal = true;
            this.selected_comment = comment;
        },
        deleteCallback(comment) {
            this.deleteModal = true;
            this.selected_comment = comment;
        },
        closeEditModal(status, evt, accept) {
            if (accept) {
                axios.put(this.$baseURL + '/ticket/comment', this.selected_comment
                )
                    .then(res => {
                        res;
                        this.$toast.success("Successfully updated comment.");
                        this.getTicket()
                    }).catch(error => {
                    this.$toast.error(error);
                })

            }

        },
        closeReplyEditModal(status, evt, accept) {
            if (accept) {
                axios.put(this.$baseURL + '/ticket/comment/reply', this.selected_reply
                )
                    .then(res => {
                        res;
                        this.$toast.success("Successfully updated reply.");
                        this.getTicket()
                    }).catch(error => {
                    this.$toast.error(error);
                })

            }

        },
        closeDelModal(status, evt, accept) {
            if (accept) {
                axios.delete(this.$baseURL + '/ticket/comment?com_id=' + this.selected_comment.com_id)
                    .then(res => {
                        res;
                        this.$toast.success("Successfully deleted comment.");
                        this.getTicket()
                    }).catch(error => {
                    this.$toast.error(error);
                })

            }

        },
        replyDelete(comment) {
            this.replyDeleteModal = true;
            this.selected_reply = comment;
        },
        closeReplyDelModal(status, evt, accept) {
            if (accept) {
                axios.delete(this.$baseURL + '/ticket/comment/reply?com_m_id=' + this.selected_reply.com_m_id)
                    .then(res => {
                        res;
                        this.$toast.success("Successfully deleted comment.");
                        this.getTicket()
                    }).catch(error => {
                    this.$toast.error(error);
                })

            }

        },
        // eslint-disable-next-line no-unused-vars
        closeCommentModal(status, evt, accept) {
            if (accept) {
                axios.post(this.$baseURL + '/ticket/comment', {
                    usr_id: this.whoamI,
                    com_text: this.comment_html,
                    ticket_id: this.ticket_data.ticket_id
                }).then(
                    (res) => {
                        res;
                        this.$toast.success("Successfully posted a comment");
                        this.getTicket();
                        this.comment_html = "";
                    }
                ).catch((error) => {
                    this.$toast.error("Failed to post comment" + error);
                })
                // console.log(this.comment_html)

            }
        },
        closeDescModal(status, evt, accept) {
            if (accept) {
                axios.put(this.$baseURL + '/ticket', {
                    ticket_title: this.ticket_title,
                    ticket_description: this.ticket_data.ticket_description,
                    ticket_id: this.ticket_data.ticket_id
                }).then(
                    (res) => {
                        res;
                        this.$toast.success("Successfully posted a comment");
                        this.getTicket();
                        this.comment_html = "";
                    }
                ).catch((error) => {
                    this.$toast.error("Failed to post comment" + error);
                })
                // console.log(this.comment_html)

            }
        },
        closeReplyCommentModal(status, evt, accept) {
            if (accept) {
                axios.post(this.$baseURL + '/ticket/comment/reply', {
                    com_id: this.reply_to_comment.com_id,
                    com_m_text: this.com_reply,
                    usr_id: this.whoamI
                }).then((res) => {
                    res;
                    this.getTicket();
                    this.com_reply = "";
                }).catch((error) =>
                    this.$toast.error(error)
                )
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page)
                }
            }
        }
    },
    mounted() {
        this.validateSession();
        this.getTicket();
    }
}
</script>
